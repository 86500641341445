import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import WarningIcon from '@material-ui/icons/Warning';
import { IAlertaEnfermedadProps } from './types';
import { RootState } from '../../../../store';
import DiagnosticoEnfermedad from '../DiagnosticoEnfermedad';
import { edadCalculada, getFechaActual, getFechaActualISO } from '../../../../common/functions';
import loading from '../../../../actions/loading/actions';
import { enfermedadesEditReferida } from '../../../../utils/editInfo';
import { removePaciente, removeUserSession } from '../../../../utils/commonStore';
import { resetMe } from '../../../../actions/me/actions';
import { limpiarRedux } from '../../../../utils/reduxCommon';
import { setAuth } from '../../../../actions/auth/actions';
import { setSnackComplete } from '../../../../actions/snackbar/types';
import { enfermedadesGuardarReferida } from '../../../../utils/sendInfo';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function AlertaEnfermedad(props: IAlertaEnfermedadProps) {
  const {
    open,
    callBackClose,
    listaEnfermedades,
    setListaEnfermedades,
    indiceRepetido,
    objForm,
    estaEnDiagnostico,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const { anio, mes, dia } = useSelector((state: RootState) => state.BasicosPaciente);
  const [opcionAlDiagnostico, setOpcionAlDiagnostico] = useState(
    estaEnDiagnostico
      ? 'diagnostico-sigue-activo-agregar-comentarios'
      : 'diagnostico-anterior-resuelto-y-este-se-va',
  );

  const handleChangeOpcionAlDiagnostico = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpcionAlDiagnostico((event.target as HTMLInputElement).value);
  };

  const diagnosticoSigueActivoAgregarComentarios = () => {
    dispatch(loading(true));
    let newComs = [];
    if (objForm.comentario.blocks.length) {
      newComs = [
        { fecha: getFechaActualISO(), comentario: objForm.comentario },
        ...listaEnfermedades[indiceRepetido].comentarios,
      ];
    } else {
      newComs = [...listaEnfermedades[indiceRepetido].comentarios];
    }
    const objDiagnostico = {
      ...listaEnfermedades[indiceRepetido],
      comentarios: newComs,
    };
    const objEnviar = {
      idMedico,
      idPaciente,
      idConsultorio,
      enfermedadReferida: objDiagnostico,
      consulta: idConsulta,
    };
    enfermedadesEditReferida(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        // verificamos que se haya hecho la inserción de la enfermedad
        if (result.code === 200) {
          const LE = [...listaEnfermedades];
          LE[indiceRepetido] = { ...objDiagnostico };
          setListaEnfermedades(LE);
          dispatch(loading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          // al darnos un resultado positivo
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
            }),
          );
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: true,
            }),
          );
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = t('campo_repetido');
          } else {
            mensaje = result.msg;
          }
          dispatch(loading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const diagnosticoAnteriorResueltoYEsteSeVa = () => {
    dispatch(loading(true));
    const objDiagnostico = {
      ...listaEnfermedades[indiceRepetido],
      estatusDelDiagnostico: 'resuelto' as 'resuelto',
    };
    const objEnviar = {
      idMedico,
      idPaciente,
      idConsultorio,
      enfermedadReferida: objDiagnostico,
      consulta: idConsulta,
    };
    enfermedadesEditReferida(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        // verificamos que se haya hecho la inserción de la enfermedad
        if (result.code === 200) {
          const LE = [...listaEnfermedades];
          LE[indiceRepetido] = { ...objDiagnostico };
          setListaEnfermedades(LE);
          dispatch(loading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          // al darnos un resultado positivo
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
            }),
          );
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: true,
            }),
          );
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = t('campo_repetido');
          } else {
            mensaje = result.msg;
          }
          dispatch(loading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const diagnosticoAnteriorResueltoCrearNuevo = () => {
    dispatch(loading(true));
    const objDiagnosticoPrev = {
      ...listaEnfermedades[indiceRepetido],
      estatusDelDiagnostico: 'resuelto' as 'resuelto',
    };
    const objEnviarPrev = {
      idMedico,
      idPaciente,
      idConsultorio,
      enfermedadReferida: objDiagnosticoPrev,
      consulta: idConsulta,
    };
    const objDiagnosticoActual = {
      idBD: objForm.idBD,
      seleccionDiagnostico: objForm.seleccionDiagnostico,
      diagnosticoCie10: objForm.diagnosticoCie10,
      diagnosticoMedico: objForm.diagnosticoMedico,
      tipoDiagnostico: objForm.tipoDiagnostico,
      certezaDelDiagnostico: objForm.certezaDelDiagnostico,
      estatusDelDiagnostico: 'activo' as 'activo',
      edadAlDiagnostico: edadCalculada(anio, mes, dia),
      fechaRegistroDiagnostico: getFechaActual(),
      comentarios: [{ fecha: getFechaActualISO(), comentario: objForm.comentario }],
      idConsulta,
    };
    const objEnviarActual = {
      idMedico,
      idPaciente,
      idConsultorio,
      enfermedadReferida: objDiagnosticoActual,
      consulta: idConsulta,
    };
    enfermedadesEditReferida(objEnviarPrev)
      .then((response) => response.json())
      .then((result) => {
        // verificamos que se haya hecho la inserción de la enfermedad
        if (result.code === 200) {
          enfermedadesGuardarReferida(objEnviarActual)
            .then((response) => response.json())
            .then((resultAc) => {
              // verificamos que se haya hecho la inserción de la enfermedad
              if (resultAc.code === 200) {
                const LE = [...listaEnfermedades];
                LE[indiceRepetido] = { ...objDiagnosticoPrev };
                LE.unshift({ ...objDiagnosticoActual, idBD: resultAc.data.idBD });
                setListaEnfermedades(LE);
                dispatch(loading(false));
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'success',
                    mensaje: t('message-success'),
                  }),
                );
                // al darnos un resultado positivo
              } else if (resultAc.code === 300) {
                // el token y el idMedico no son el mismo
                /* Cerrar sesión */
                removeUserSession();
                dispatch(resetMe());
                limpiarRedux();
                dispatch(
                  setAuth({
                    id: '',
                    suscrito: false,
                    acreditado: false,
                  }),
                );
                history.push('/login');
              } else if (resultAc.code === 301) {
                // el medico no tiene subscripcion activa
                /* Resetear y redirigir al checkout */
                removePaciente();
                limpiarRedux();
                dispatch(
                  setAuth({
                    id: '',
                    suscrito: false,
                    acreditado: true,
                  }),
                );
                history.push('/subscripcion');
              } else if (resultAc.code === 302) {
                // el paciente y el medico no tienen relacion
                /* Resetear redux paciente y datos paciente y redirigir a paciente */
                removePaciente();
                limpiarRedux();
                history.push('/pacientes');
              } else {
                let mensaje = '';
                if (/UNIQUE KEY/i.test(resultAc.msg)) {
                  mensaje = t('campo_repetido');
                } else {
                  mensaje = result.msg;
                }
                dispatch(loading(false));
                dispatch(
                  setSnackComplete({
                    open: true,
                    severity: 'error',
                    mensaje: `${t('message-error')} ${mensaje}`,
                  }),
                );
              }
            })
            .catch((err) => {
              dispatch(loading(false));
              dispatch(
                setSnackComplete({
                  open: true,
                  severity: 'error',
                  mensaje: `${t('message-error-warning')} ${err.toString()}`,
                }),
              );
            });
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
            }),
          );
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: true,
            }),
          );
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else {
          let mensaje = '';
          if (/UNIQUE KEY/i.test(result.msg)) {
            mensaje = t('campo_repetido');
          } else {
            mensaje = result.msg;
          }
          dispatch(loading(false));
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error')} ${mensaje}`,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(loading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  const clickAceptar = () => {
    switch (opcionAlDiagnostico) {
      case 'diagnostico-sigue-activo-agregar-comentarios':
        diagnosticoSigueActivoAgregarComentarios();
        break;
      case 'diagnostico-anterior-resuelto-y-este-se-va':
        diagnosticoAnteriorResueltoYEsteSeVa();
        break;
      case 'diagnostico-anterior-resuelto-crear-nuevo':
        diagnosticoAnteriorResueltoCrearNuevo();
        break;
      default:
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={callBackClose}
      aria-labelledby="delete-dialog-titulo"
      aria-describedby="delete-dialog-descripcion"
      maxWidth="lg"
    >
      <div className="flex justify-between items-center bg-blue-500 text-white pl-4">
        <span className="text-lg">{t('_atencion_')}</span>
        <IconButton aria-label="cerrar" color="inherit" onClick={callBackClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <WarningIcon className="text-yellow-500" color="inherit" />
        <DialogContentText>
          {t('ya_existe_este_diagnostico_registrado_y_esta_activo_en_los_antecedentes_patologicos')}
        </DialogContentText>
        <DiagnosticoEnfermedad diagnostico={listaEnfermedades[indiceRepetido]} />
        <div className="flex justify-center">
          <RadioGroup
            aria-label="seleccion"
            value={opcionAlDiagnostico}
            onChange={handleChangeOpcionAlDiagnostico}
          >
            {estaEnDiagnostico && (
              <FormControlLabel
                value="diagnostico-sigue-activo-agregar-comentarios"
                control={<Radio color="primary" />}
                label={t('diagnostico-sigue-activo-agregar-comentarios')}
              />
            )}
            <FormControlLabel
              value="diagnostico-anterior-resuelto-y-este-se-va"
              control={<Radio color="primary" />}
              label={t('diagnostico-anterior-resuelto-y-este-se-va')}
            />
            <FormControlLabel
              value="diagnostico-anterior-resuelto-crear-nuevo"
              control={<Radio color="primary" />}
              label={t('diagnostico-anterior-resuelto-crear-nuevo')}
            />
          </RadioGroup>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            clickAceptar();
            callBackClose(e);
          }}
          color="primary"
          variant="contained"
        >
          {t('aceptar')}
        </Button>
        <Button onClick={callBackClose} color="primary" variant="outlined">
          {t('cancelar')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertaEnfermedad;
