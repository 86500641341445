/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { ReactComponent as H1 } from '../../../../../../assets/shape/H1.svg';
import { ReactComponent as H2 } from '../../../../../../assets/shape/H2.svg';
import { ReactComponent as H3 } from '../../../../../../assets/shape/H3.svg';
import { ReactComponent as H4 } from '../../../../../../assets/shape/H4.svg';
import { ReactComponent as H5 } from '../../../../../../assets/shape/H5.svg';
import { ReactComponent as M1 } from '../../../../../../assets/shape/M1.svg';
import { ReactComponent as M2 } from '../../../../../../assets/shape/M2.svg';
import { ReactComponent as M3 } from '../../../../../../assets/shape/M3.svg';
import { ReactComponent as M4 } from '../../../../../../assets/shape/M4.svg';
import { ReactComponent as M5 } from '../../../../../../assets/shape/M5.svg';
import { RootState } from '../../../../../../store';
import { IEvaluacionMedidasProps } from './types';

function EvaluacionMedidas({
  setActiveStep,
  peso,
  talla,
  perCintura,
  imc,
}: IEvaluacionMedidasProps) {
  const { t } = useTranslation();
  const { sexo } = useSelector((state: RootState) => state.BasicosPaciente);
  const { secciones } = useSelector((state: RootState) => state.Me);
  const [warnMedidas, setWarnMedidas] = useState<boolean>(false);
  const [warnPeso, setWarnPeso] = useState<boolean>(false);
  const [warnIMC, setWarnIMC] = useState<boolean>(false);
  const [warnCintura, setWarnCintura] = useState<boolean>(false);
  const checarValorIMC = () => {
    let txtPeso = '';
    if (imc) {
      if (imc < 18.5) {
        txtPeso = t('peso_bajo');
      }
      if (imc < 24.9) {
        txtPeso = t('normal');
      }
      if (imc < 29.9) {
        txtPeso = t('sobrepeso');
      }
      if (imc < 39.9) {
        txtPeso = t('obesidad');
      }
      if (imc >= 40) {
        txtPeso = t('obesidad_morbida');
      }
      txtPeso = `(${txtPeso})`;
    }
    return txtPeso;
  };
  const obtenerShapeIMC = () => {
    if (imc) {
      if (sexo === 'M') {
        if (imc < 18.5) {
          return <M1 />;
        }
        if (imc < 24.9) {
          return <M2 />;
        }
        if (imc < 29.9) {
          return <M3 />;
        }
        if (imc < 39.9) {
          return <M4 />;
        }
        if (imc >= 40) {
          return <M5 />;
        }
      } else {
        if (imc < 18.5) {
          return <H1 />;
        }
        if (imc < 24.9) {
          return <H2 />;
        }
        if (imc < 29.9) {
          return <H3 />;
        }
        if (imc < 39.9) {
          return <H4 />;
        }
        if (imc >= 40) {
          return <H5 />;
        }
      }
      return <div>{t('error')}</div>;
    }
    return '';
  };
  const pesoMinimo = () => {
    if (talla) {
      return parseInt((talla * talla * 18.5).toFixed(1), 10);
    }
    return 0;
  };
  const pesoMaximo = () => {
    if (talla) {
      return parseInt((talla * talla * 24.9).toFixed(1), 10);
    }
    return 0;
  };
  useEffect(() => {
    if (peso && (peso < pesoMinimo() || peso > pesoMaximo())) {
      setWarnPeso(true);
      setWarnMedidas(true);
    }
    if (imc && (imc < 18.5 || imc > 24.9)) {
      setWarnIMC(true);
      setWarnMedidas(true);
    }
  }, []);
  useEffect(() => {
    if (sexo === 'M') {
      if (perCintura && perCintura >= 80) {
        setWarnCintura(true);
        setWarnMedidas(true);
      }
    } else if (perCintura && perCintura >= 95) {
      setWarnCintura(true);
      setWarnMedidas(true);
    }
  }, [sexo]);
  if (!(peso && talla && perCintura)) {
    return (
      <div className="border border-solid border-red-500 rounded-lg w-full pl-8">
        <h3 className="text-red-500 font-normal">
          {`${t('falta_reportar_los_siguientes_datos')}:`}
        </h3>
        <ul>
          {!peso && (
            <li className="text-red-500">
              <span className="text-gray-500">{t('peso')}</span>
              {setActiveStep !== undefined
                && secciones.length > 0
                && secciones.find((valor) => valor.idSeccion === 46) && (
                  <Button color="primary" onClick={() => setActiveStep('exploracion-fisica')}>
                    {`(${t('ir_a_la_exploracion_fisica')})`}
                  </Button>
              )}
            </li>
          )}
          {!talla && (
            <li className="text-red-500">
              <span className="text-gray-500">{t('talla')}</span>
              {setActiveStep !== undefined
                && secciones.length > 0
                && secciones.find((valor) => valor.idSeccion === 46) && (
                  <Button color="primary" onClick={() => setActiveStep('exploracion-fisica')}>
                    {`(${t('ir_a_la_exploracion_fisica')})`}
                  </Button>
              )}
            </li>
          )}
          {!perCintura && (
            <li className="text-red-500">
              <span className="text-gray-500">{t('perimetro_de_la_cintura')}</span>
              {setActiveStep !== undefined
                && secciones.length > 0
                && secciones.find((valor) => valor.idSeccion === 46) && (
                  <Button color="primary" onClick={() => setActiveStep('exploracion-fisica')}>
                    {`(${t('ir_a_la_exploracion_fisica')})`}
                  </Button>
              )}
            </li>
          )}
        </ul>
      </div>
    );
  }
  return (
    <div className="flex w-full">
      <div className="border-0 border-solid border-gray-400 border-r w-full">
        <div className="flex justify-around">
          <div className="h-full">{obtenerShapeIMC()}</div>
          <div>
            <div className="flex items-center">
              {warnMedidas && (
                <span className="text-red-500 mr-2">
                  <WarningIcon />
                </span>
              )}
              <h3 className="text-blue-500 font-normal">
                <span>{t('medidas_actuales')}</span>
              </h3>
            </div>
            <div className="grid grid-cols-2 gap-x-2 gap-y-4">
              <span className={warnPeso ? 'text-red-500' : 'text-gray-900'}>{`${t('peso')}:`}</span>
              <span className="text-gray-500">{`${peso} kg`}</span>
              <span className={warnIMC ? 'text-red-500' : 'text-gray-900'}>{`${t('imc')}:`}</span>
              <span className="text-gray-500">{`${imc} ${checarValorIMC()}`}</span>
              <span className={warnCintura ? 'text-red-500' : 'text-gray-900'}>
                {`${t('perimetro_de_la_cintura')}:`}
              </span>
              <span className="text-gray-500">{`${perCintura} cm`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="border-0 border-solid border-gray-400 border-l w-full">
        {sexo === 'M' ? (
          <div className="flex justify-around">
            <div className="h-full">
              <M2 />
            </div>
            <div>
              <h3 className="text-blue-500 font-normal">{t('metas')}</h3>
              <div className="grid grid-cols-2 gap-x-2 gap-y-4">
                <span className="text-gray-900">{`${t('peso_saludable')}:`}</span>
                <span className="text-gray-500">{`${pesoMinimo()} a ${pesoMaximo()} kg`}</span>
                <span className="text-gray-900">{`${t('imc')}:`}</span>
                <span className="text-gray-500">18.5 - 24.9</span>
                <span className="text-gray-900">{`${t('perimetro_de_la_cintura')}:`}</span>
                <span className="text-gray-500">&lt; 80 cm</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-around">
            <div className="h-full px-4">
              <H2 />
            </div>
            <div>
              <h3 className="text-blue-500 font-normal">{t('metas')}</h3>
              <div className="grid grid-cols-2 gap-x-2 gap-y-4">
                <span className="text-gray-900">{`${t('peso')}:`}</span>
                <span className="text-gray-500">
                  {`${talla && (talla * talla * 18.5).toFixed(1)} a ${
                    talla && (talla * talla * 24.9).toFixed(1)
                  } kg`}
                </span>
                <span className="text-gray-900">{`${t('imc')}:`}</span>
                <span className="text-gray-500">18.5 - 24.9</span>
                <span className="text-gray-900">{`${t('perimetro_de_la_cintura')}:`}</span>
                <span className="text-gray-500">&lt; 94 cm</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EvaluacionMedidas;
