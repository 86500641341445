import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, withStyles } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { IConstructorTabla } from './types';
import useStyles from '../styles';
import { ISintomaAYS } from '../types';

const WhiteCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const BlackRadio = withStyles({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const BlackTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

function ConstructorTabla(props: IConstructorTabla) {
  const { arrSintomas, setArrResultados, arrResultados } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [valorRadios, setValorRadios] = useState<string[]>([]);
  const [comentarios, setComentarios] = useState<string[]>([]);
  const checkNegados = (tabla: ISintomaAYS[]) => !tabla.find(
    (area) => (area.nombreSintoma !== 'Otros' && area.tieneSintoma !== false)
        || (area.nombreSintoma === 'Otros' && area.tieneSintoma === true),
  );
  const [checkNegado, setCheckNegado] = useState<boolean>(false);

  useEffect(() => {
    if (arrResultados.length) {
      setValorRadios(
        arrResultados.map((res) => (res.tieneSintoma === null ? '' : ['NO', 'SI'][Number(res.tieneSintoma)])),
      );
      setComentarios(arrResultados.map((res) => res.comentarioSintoma));
    } else {
      const arrVacio = arrSintomas.map(() => '');
      setValorRadios([...arrVacio]);
      setComentarios([...arrVacio]);
    }
  }, [arrSintomas]);
  // aqui tengo el detalle del lag, quiza otra manera de allamar al array sin hacer esto
  useEffect(() => {
    const resultados = arrSintomas.map((sintoma, index) => ({
      nombreSintoma: t(sintoma),
      comentarioSintoma: comentarios[index],
      tieneSintoma: valorRadios[index] !== '' ? valorRadios[index] === 'SI' : null,
    }));
    setArrResultados(resultados);
    setCheckNegado(checkNegados(resultados));
  }, [valorRadios, comentarios]);

  const handleChangeRadioSeleccion = (event: React.ChangeEvent<HTMLInputElement>, indx: number) => {
    setValorRadios((prevVal) => prevVal.map((rads, jdx) => {
      if (jdx === indx) {
        return event.target.value;
      }
      return rads;
    }));
  };

  const handleChangeComentarios = (event: React.ChangeEvent<{ value: unknown }>, indx: number) => {
    setComentarios((prevVal) => prevVal.map((comms, jdx) => {
      if (jdx === indx) {
        return event.target.value as string;
      }
      return comms;
    }));
  };

  const handleChangeCheckNegado = (event: React.ChangeEvent<HTMLInputElement>) => {
    const check = event.target.checked as boolean;
    const newArrResultados: ISintomaAYS[] = [];
    const newValorRadios: string[] = [];
    arrResultados.forEach((area) => {
      const tieneSintoma = check && (area.nombreSintoma !== 'Otros' || area.tieneSintoma !== null) ? false : null;
      newArrResultados.push({
        nombreSintoma: area.nombreSintoma,
        tieneSintoma,
        comentarioSintoma: area.comentarioSintoma,
      });
      newValorRadios.push(tieneSintoma === false ? 'NO' : '');
    });
    setArrResultados(newArrResultados);
    setValorRadios(newValorRadios);
  };

  return (
    <div className="border border-solid border-gray-600">
      <div className="grid grid-cols-12 gap-4 bg-blue-600 text-white text-center py-2 items-center">
        <span>{t('si')}</span>
        <div className="text-left">
          <WhiteCheckbox
            name="checkbox-all"
            checked={checkNegado}
            onChange={handleChangeCheckNegado}
          />
          <span>{t('no')}</span>
        </div>
        <span className="col-span-2 text-left">{t('sintoma')}</span>
        <span className="col-span-8">{t('comentarios')}</span>
      </div>
      {arrSintomas.map((single, index) => (
        <div
          className={`grid grid-cols-12 gap-4 ${
            index % 2 === 0 ? 'bg-blue-300' : ''
          } text-center items-center py-4`}
          key={single}
        >
          <div className="col-span-1">
            <BlackRadio
              value="SI"
              name={`radio-button-general-${index}`}
              checked={valorRadios[index] === 'SI'}
              onChange={(e) => handleChangeRadioSeleccion(e, index)}
            />
          </div>
          <div className="col-span-1">
            <BlackRadio
              value="NO"
              name={`radio-button-general-${index}`}
              checked={valorRadios[index] === 'NO'}
              onChange={(e) => handleChangeRadioSeleccion(e, index)}
            />
          </div>
          <span className="col-span-2 text-left">{t(single)}</span>
          <div className="col-span-8 pr-4">
            <BlackTextField
              multiline
              rows="2"
              variant="outlined"
              fullWidth
              InputProps={{
                className: classes.backgroundWhite,
                inputProps: { maxLength: 500, autoComplete: 'off' },
              }}
              value={comentarios[index]}
              onChange={(e) => handleChangeComentarios(e, index)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ConstructorTabla;
