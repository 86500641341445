import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector, useDispatch } from 'react-redux';
import { IListaDiagnosticosProps, IListaElementoProps } from './types';
import DeleteDialog from '../../../../../components/DeleteDialog';
import ViewComentarios from '../../../../../components/Comentarios/view';
import ModalPronostico from '../../Plan/Pronostico/ListaPronosticos/ModalPronostico/index';
import { RootState } from '../../../../../store';
import { formatoDeIsoAFechaHora, stringMes } from '../../../../../common/functions';
import { getToken, getPronosticosEnfermedad } from '../../../../../utils/commonUrl';
import { setSnackComplete } from '../../../../../actions/snackbar/types';
import useStyles from './styles';
import { enfermedadesEliminarReferida } from '../../../../../utils/deleteInfo';
import { setRequest } from '../../../../../actions/request/types';

function ListaDiagnosticos({
  diagnostico,
  idDiagnostico,
  setIdDiagnostico,
  enfReferidas,
  setEnfReferidas,
}: IListaDiagnosticosProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { idPaciente } = useSelector((state: RootState) => state.BasicosPaciente);
  const { idConsulta: consulta } = useSelector((state: RootState) => state.Consulta);
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const {
    consultorio: { idConsultorio },
  } = useSelector((state: RootState) => state.Consultorios);
  const [arrayModal, setArrayModal] = useState<IListaElementoProps[]>([]);
  const borderDiagnostico = () => {
    let border = 'rounded p-4 w-full';
    if (
      diagnostico.certezaDelDiagnostico === 'presuntivo'
      && diagnostico.estatusDelDiagnostico !== 'resuelto'
    ) {
      border = 'border-0 border-solid border-red-500 rounded border-l-8 p-4 w-full';
    } else if (
      (diagnostico.certezaDelDiagnostico === 'confirmado'
        && diagnostico.estatusDelDiagnostico !== 'resuelto')
      || diagnostico.estatusDelDiagnostico === 'activo'
    ) {
      border = 'border-0 border-solid border-blue-500 rounded border-l-8 p-4 w-full';
    }
    return border;
  };

  const tituloDiagnostico = () => (diagnostico.diagnosticoCie10.id.length > 0
    ? diagnostico.diagnosticoCie10.label
    : diagnostico.diagnosticoMedico);

  // cierre del modal
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [editAlert, setEditAlert] = useState<boolean>(false);
  const [petInfo, setPetInfo] = useState<boolean>(false);
  const [openPronosticos, setOpenPronosticos] = useState<boolean>(false);

  const handleDeleteAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteAlert(false);
  };

  const handleEditAlertClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEditAlert(false);
  };

  const editDiagnostico = () => {
    setIdDiagnostico(diagnostico.idBD);
    setEditAlert(false);
    window.scrollTo({ top: 550, left: 0, behavior: 'smooth' });
  };

  const handleFindData = (idCat: string) => {
    dispatch(
      setSnackComplete({
        open: true,
        severity: 'info',
        mensaje: t('d-p-plan-pron-find'),
      }),
    );
    const token = getToken();
    fetch(getPronosticosEnfermedad(idMedico, idPaciente, idConsultorio, idCat), {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200 && result.data && result.data.length > 0) {
          setArrayModal(result.data);
        } else if (result.code === 200 && result.data && result.data.length === 0) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'warning',
              mensaje: t('d-p-plan-pron-find-warning'),
            }),
          );
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: t('d-p-plan-pron-find-warning'),
            }),
          );
        }
        setPetInfo(true);
      })
      .catch(() => {
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: t('d-p-plan-pron-find-error'),
          }),
        );
        setArrayModal([]);
        setPetInfo(true);
      });
  };

  const deleteDiagnostico = () => {
    dispatch(
      setRequest({
        type: 'delete',
        requestFunction: enfermedadesEliminarReferida({
          idMedico,
          idPaciente,
          idConsultorio,
          idBD: diagnostico.idBD,
          consulta,
        }),
        successFunction: () => {
          setEnfReferidas(enfReferidas.filter((e) => e.idBD !== diagnostico.idBD));
        },
      }),
    );
  };

  const handleEditDiagnostico = () => {
    if (idDiagnostico !== -1 && idDiagnostico !== diagnostico.idBD) {
      setEditAlert(true);
    } else {
      editDiagnostico();
    }
  };

  const handleDeleteDiagnostico = () => {
    setDeleteAlert(true);
  };

  useEffect(() => {
    if (petInfo && arrayModal.length > 0) {
      setOpenPronosticos(true);
    }
  }, [arrayModal, petInfo]);

  return (
    <div className="shadow-md border border-solid border-gray-100 rounded mb-4 flex justify-between relative">
      <DeleteDialog
        open={deleteAlert}
        titulo={t('_atencion_')}
        descripcion={t('antecedentes-pnp-historias-eliminar')}
        callBackAceptar={deleteDiagnostico}
        callBackClose={handleDeleteAlertClose}
      />
      <DeleteDialog
        open={editAlert}
        titulo={t('_atencion_')}
        descripcion={t('si_quieres_editar_este_diagnostico_se_borrara_el_que_estas_creando')}
        callBackAceptar={editDiagnostico}
        callBackClose={handleEditAlertClose}
      />
      <ModalPronostico
        open={openPronosticos}
        callBackClose={() => {
          setOpenPronosticos(false);
          setPetInfo(false);
        }}
        arrayData={arrayModal}
      />
      {diagnostico.certezaDelDiagnostico !== '' && (
        <h4
          className={`font-normal mt-4 mr-4 absolute top-0 right-0${
            diagnostico.certezaDelDiagnostico === 'presuntivo' ? ' text-red-500' : ' text-blue-500'
          }`}
        >
          {t(diagnostico.certezaDelDiagnostico)}
        </h4>
      )}
      <div className="mb-2 mr-4 absolute bottom-0 right-0">
        <Button
          color="primary"
          size="small"
          className={classes.noTextTranform}
          onClick={() => handleFindData(diagnostico.idBD.toString())}
        >
          {t('d-p-enfermedad-pron-ver')}
        </Button>
      </div>
      <div className={borderDiagnostico()}>
        <h2 className="m-0 overflow-ellipsis overflow-hidden font-normal">{tituloDiagnostico()}</h2>
        <div className="flex">
          {diagnostico.edadAlDiagnostico.edad !== 0 && (
            <h4 className="font-normal text-gray-500 m-0 mr-4">
              {`${t('edad_al_diagnostico')}: ${diagnostico.edadAlDiagnostico.edad} ${t(
                diagnostico.edadAlDiagnostico.periodo,
              )}`}
            </h4>
          )}
          {diagnostico.fechaRegistroDiagnostico.anio && (
            <h4 className="font-normal text-gray-500 m-0">
              {`${t('fecha_de_registro_del_diagnostico')}: ${
                diagnostico.fechaRegistroDiagnostico.dia
                  ? `${diagnostico.fechaRegistroDiagnostico.dia}/`
                  : ''
              }${
                diagnostico.fechaRegistroDiagnostico.mes
                  ? `${stringMes(diagnostico.fechaRegistroDiagnostico.mes, t)}/`
                  : ''
              }${diagnostico.fechaRegistroDiagnostico.anio}.`}
            </h4>
          )}
        </div>
        <div className="max-h-24 overflow-y-auto mt-2 w-full">
          {diagnostico.comentarios.length > 0
            && diagnostico.comentarios.map((com) => (
              <div key={com.fecha}>
                <h4 className="font-normal text-gray-500 m-0">
                  {`${t('fecha_del_comentario')}: ${formatoDeIsoAFechaHora(com.fecha, t)}`}
                </h4>
                <div className="mb-4">
                  <ViewComentarios comentarios={com.comentario} />
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="min-w-max self-center">
        <IconButton aria-label="edit" color="primary" onClick={handleEditDiagnostico}>
          <EditIcon />
        </IconButton>
        <IconButton aria-label="delete" color="primary" onClick={handleDeleteDiagnostico}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ListaDiagnosticos;
