import { Dispatch, SetStateAction } from 'react';

export interface IDiagnosticoGeneral {
  nombre: string;
  posee: '' | 'si' | 'no';
  medicamento: string;
  noMedicamento: boolean;
  sexo: 'H' | 'M' | 'A';
  validado: boolean;
}

// export interface IDiagnosticoGeneral {
//   actualizado: boolean;
//   nombre: string;
//   posee: '' | 'si' | 'no';
//   medicamentos: string[];
//   noUsaMedicamento: boolean;
//   validado: boolean;
// }

export interface IDiagnosticosGenerales {
  loaded: boolean;
  contenidoTabla: IDiagnosticoGeneral[];
}

export const diagnosticosGeneralesInitial: IDiagnosticosGenerales = {
  loaded: false,
  contenidoTabla: [],
};

export interface IDiagnosticosGeneralesProps {
  setActiveSubStep: Dispatch<
  SetStateAction<
  'personales-patologicos' | 'personales-no-patologicos' | 'familiares' | 'laborales'
  >
  >;
  diagnosticosGenerales: IDiagnosticosGenerales;
  setDiagnosticosGenerales: Dispatch<SetStateAction<IDiagnosticosGenerales>>;
}
