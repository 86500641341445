import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  AppBar, Toolbar, IconButton, Avatar, CssBaseline, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuPaciente from './MenuPaciente';
import useStyles from './styles';
import SideBarPaciente from '../SideBarPaciente/index';
import { RootState } from '../../store';
import { sendLoguot } from '../../utils/sendInfo';
import loading from '../../actions/loading/actions';
import { getToken, getUser, removeUserSession } from '../../utils/commonStore';
import { resetMe } from '../../actions/me/actions';
import { ReactComponent as LogoIcon } from '../../assets/logo/Logotipo_Color.svg';
import { setSnackComplete } from '../../actions/snackbar/types';
import { limpiarRedux } from '../../utils/reduxCommon';
import { setAuth } from '../../actions/auth/actions';

function NavbarPaciente() {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /** state del componente */
  const [pacienteEl, setPacienteEl] = useState<null | HTMLButtonElement>(null);
  const [pacienteName, setPacienteName] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const openPacienteMenu = Boolean(pacienteEl);
  const { nombre, primerApellido, segundoApellido } = useSelector((state: RootState) => state.Me);
  useEffect(() => {
    setPacienteName(
      `${nombre ? nombre[0] : ''}${primerApellido ? primerApellido[0] : ''}${
        segundoApellido ? segundoApellido[0] : ''
      }`,
    );
  }, [nombre, primerApellido, segundoApellido]);

  /** Abrir el menú doctor */
  const handleMenuPaciente = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPacienteEl(e.currentTarget);
  };

  /** Cerrar el menú del doctor */
  const handleCloseDoctor = () => {
    setPacienteEl(null);
  };

  /** Abrir el Sidebar */
  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };

  /** Cerrar el Sidebar */
  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const cerrarSesion = () => {
    dispatch(loading(true));
    const obj = { token: getToken(), usuario: getUser() };
    sendLoguot(obj)
      .then((result) => result.json())
      .then((data) => {
        if (data.code === 200) {
          removeUserSession();
          dispatch(resetMe());
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
            }),
          );
          limpiarRedux();
          history.push('/login-patient');
        }
        dispatch(loading(false));
      })
      .catch((err) => {
        if (err.toString() === 'SyntaxError: Unexpected token E in JSON at position 0') {
          removeUserSession();
          dispatch(resetMe());
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
            }),
          );
          limpiarRedux();
          history.push('/login-patient');
        } else {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t('message-error-warning')} ${err.toString()}`,
            }),
          );
        }
        dispatch(loading(false));
      });
  };

  /** Regresa el navbar y el sidebar */
  return (
    <>
      <CssBaseline />
      <div className="text-white bg-white z-30">
        <AppBar
          position="fixed"
          color="inherit"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: sidebarOpen,
          })}
        >
          <Toolbar>
            <div className="text-gray-900">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="expandir menu"
                onClick={handleSidebarOpen}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div className="text-gray-900 w-100 flex-grow">
              <LogoIcon height="55" />
            </div>
            <Button
              color="default"
              startIcon={<PersonIcon />}
              endIcon={(
                <Avatar variant="rounded" style={{ backgroundColor: '#1D4ED8' }} alt={pacienteName}>
                  {pacienteName}
                </Avatar>
              )}
              onClick={handleMenuPaciente}
              disableElevation
            >
              {`${nombre} ${primerApellido} ${segundoApellido}`}
            </Button>
            <MenuPaciente
              anchorEl={pacienteEl}
              handleClose={handleCloseDoctor}
              open={openPacienteMenu}
              handleLogout={cerrarSesion}
            />
          </Toolbar>
        </AppBar>
      </div>
      <SideBarPaciente sidebarOpen={sidebarOpen} handleSidebarClose={handleSidebarClose} />
    </>
  );
}

export default NavbarPaciente;
