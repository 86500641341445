import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Paper,
  Button,
  TextField,
  Select,
  FormControl,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import ShortTextIcon from '@material-ui/icons/ShortText';
import SubjectIcon from '@material-ui/icons/Subject';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import AppsIcon from '@material-ui/icons/Apps';
import TodayIcon from '@material-ui/icons/Today';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from './styles';
import { IBuilderErrores, IBuilderProps } from './types';
import BuilderHora from './Scaffold/Hora/constructor';
import BuilderFecha from './Scaffold/Fecha/constructor';
import BuilderEscala from './Scaffold/EscalaLineal/constructor';
import BuilderRespuestaBreve from './Scaffold/RespuestaBreve/constructor';
import BuilderListaDesplegable from './Scaffold/ListaDesplegable/constructor';
import BuilderCasillasVerificacion from './Scaffold/CasillasVerificacion/constructor';
import BuilderParrafo from './Scaffold/Parrafo/constructor';
import BuilderOpcionMultiple from './Scaffold/OpcionMultiple/constructor';
import BuilderTabla from './Scaffold/Tabla/constructor';
import { RootState } from '../../reducer';
import { builderGuardarVariable } from '../../utils/sendInfo';
import { setSnackComplete } from '../../actions/snackbar/types';
import { IConstructorComponenteMCPA } from './Scaffold/types';
import { uniqueObjArray, uniqueStringArray } from '../../common/funcionesArrays';
import { setLoading } from '../../actions/loading/actions';
import { removePaciente, removeUserSession } from '../../utils/commonStore';
import { resetMe } from '../../actions/me/actions';
import { limpiarRedux } from '../../utils/reduxCommon';
import { setAuth } from '../../actions/auth/actions';

/** Builder la parte de agregar variable */
function Builder(props: IBuilderProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    index, value, regresar, nombreDelMC, setArrComponentes,
  } = props;
  /** States del componente funcional */
  const { idMedico } = useSelector((state: RootState) => state.Me);
  const [seleccionaFormato, setSeleccionaFormato] = useState<string>('');
  // La funcion (callback) que ejecutaran los componentes hijos para que el componente sepa,
  // que constructor están utilizando
  const [constructor, setConstructor] = useState<IConstructorComponenteMCPA>({
    componente: '',
    datosComponente: {
      titulo: '',
      descripcion: '',
    },
    constructor: {
      titulo: '',
      descripcion: '',
      comentarios: '',
      valor: '',
    },
    valores: {
      textoLibre: '',
      valor: '',
    },
  });
  const [paso, setPaso] = useState<number>(0);
  /** Catalogos */
  /** Logica */
  const resetConstructor = () => {
    setConstructor({
      componente: '',
      datosComponente: {
        titulo: '',
        descripcion: '',
      },
      constructor: {
        titulo: '',
        descripcion: '',
        comentarios: '',
        valor: '',
      },
      valores: {
        textoLibre: '',
        valor: '',
      },
    });
  };
  /** Hooks */
  /** handlers de desabilitacion o no de otros campos */
  const [errores, setErrores] = useState<IBuilderErrores>({
    errorAutocomplete: false,
    errorEspecifica: false,
    errorAlMenosDosOpciones: false,
  });
  const [disableContinuar, setDisableContinuar] = useState<boolean>(false);
  const [refSeleccionaFormato, setRefSeleccionaFormato] = useState<boolean>(false);

  /** Efecto al cargar la pagina */
  /** Handlers del componente */
  const handleChangeNombreVariable = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    setConstructor((preCons) => ({
      ...preCons,
      datosComponente: {
        ...preCons.datosComponente,
        titulo: val,
      },
      constructor: {
        ...preCons.constructor,
        titulo: val,
      },
    }));
    if (val.length) {
      setRefSeleccionaFormato(true);
    } else {
      setRefSeleccionaFormato(false);
      setPaso(0);
      setSeleccionaFormato('');
    }
  };

  const handleChangeSeleccionaFormato = (e: React.ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as string;
    const newObj = {
      componente: '',
      datosComponente: {
        titulo: constructor.datosComponente.titulo,
        descripcion: constructor.datosComponente.descripcion,
      },
      constructor: {
        titulo: constructor.constructor.titulo,
        descripcion: constructor.constructor.descripcion,
        comentarios: constructor.constructor.comentarios,
        valor: constructor.constructor.comentarios,
      },
      valores: {
        textoLibre: constructor.valores.textoLibre,
        valor: constructor.valores.valor,
      },
    } as IConstructorComponenteMCPA;
    switch (val) {
      case '7':
        newObj.componente = 'TABLA';
        newObj.constructor.tabla = {
          columnas: [],
          renglones: [],
          datos: [],
          unaVarOpc: true,
        };
        newObj.valores.tabla = [];
        break;
      case '9':
        newObj.componente = 'HORA';
        newObj.constructor.hora = { horario: '' };
        newObj.valores.hora = '';
        break;
      case '8':
        newObj.componente = 'FECHA';
        newObj.constructor.fecha = {
          dia: '',
          mes: '',
          anio: '',
        };
        break;
      case '6':
        newObj.componente = 'ESCALA_LINEAL';
        newObj.constructor.escalaLineal = {
          valor: {
            inicial: 0,
            final: 10,
          },
          escala: {
            inicial: '',
            final: '',
          },
        };
        newObj.valores.escalaLineal = {
          valor: {
            inicial: 0,
            final: 10,
          },
          escala: {
            inicial: '',
            final: '',
          },
        };
        break;
      case '5':
        newObj.componente = 'LISTA_DESPLEGABLE';
        newObj.constructor.listaDesplegable = {
          arrValores: ['', ''],
          seleccionListaDesplegable: [],
          ordenar: false,
          seleccionMultiple: false,
        };
        newObj.valores.listaDesplegable = [];
        break;
      case '4':
        newObj.componente = 'CASILLA_VERIFICACION';
        newObj.constructor.casillasOpciones = {
          arrOpciones: [
            { nombreOpcion: '', checked: false, arraySubOpciones: [] },
            { nombreOpcion: '', checked: false, arraySubOpciones: [] },
          ],
        };
        newObj.valores.casillasOpciones = {
          arrOpciones: [],
        };
        break;
      case '3':
        newObj.componente = 'OPCION_MULTIPLE';
        newObj.constructor.casillasOpciones = {
          arrOpciones: [
            { nombreOpcion: '', checked: false, arraySubOpciones: [] },
            { nombreOpcion: '', checked: false, arraySubOpciones: [] },
          ],
        };
        newObj.valores.casillasOpciones = {
          arrOpciones: [],
        };
        break;
      case '2':
        newObj.componente = 'PARRRAFO';
        break;
      case '1':
        newObj.componente = 'RESPUESTA_BREVE';
        newObj.constructor.respuestaBreve = {
          unidad: {
            id: 0,
            label: '',
          },
          otraUnidad: '',
        };
        newObj.valores.respuestaBreve = '';
        break;
      default:
        break;
    }
    setConstructor(newObj);
    setSeleccionaFormato(val);
    setDisableContinuar(false);
    setErrores({
      errorAutocomplete: false,
      errorEspecifica: false,
      errorAlMenosDosOpciones: false,
    });
    setPaso(0);
  };
  /** states para mostrar o no diferentes campos */
  /** handles de disabled components */
  /** Handlers para enviar la informacion */
  /** Recibe un parametro opcional en caso que hayamos modificadao algun state
   * y tengamos problemas con el asincronamiento */
  const enviarInformacion = () => {
    if (!constructor.datosComponente.titulo) {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: `${t('message-error-warning')} ${t('no_guardar_sin_titulo')}`,
        }),
      );
      return;
    }
    dispatch(setLoading(true));
    const objEnviar = {
      idMedico,
      constructor,
      titulo: constructor.datosComponente.titulo,
      descripcion: constructor.datosComponente.descripcion,
      tipo: seleccionaFormato,
      componente: constructor.componente,
    };
    builderGuardarVariable(objEnviar)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'success',
              mensaje: t('message-success'),
            }),
          );
          setSeleccionaFormato('');
          setPaso(0);
          setRefSeleccionaFormato(false);
          resetConstructor();
          setArrComponentes((preAC) => preAC.concat({
            id: result.idConstructor as number,
            formato: parseInt(seleccionaFormato, 10) || 0,
            tipo: false,
            titulo: constructor.datosComponente.titulo,
            constructorComponente: constructor,
          }));
          regresar(false);
        } else if (result.code === 300) {
          // el token y el idMedico no son el mismo
          /* Cerrar sesión */
          removeUserSession();
          dispatch(resetMe());
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: false,
            }),
          );
          history.push('/login');
        } else if (result.code === 301) {
          // el medico no tiene subscripcion activa
          /* Resetear y redirigir al checkout */
          removePaciente();
          limpiarRedux();
          dispatch(
            setAuth({
              id: '',
              suscrito: false,
              acreditado: true,
            }),
          );
          history.push('/subscripcion');
        } else if (result.code === 302) {
          // el paciente y el medico no tienen relacion
          /* Resetear redux paciente y datos paciente y redirigir a paciente */
          removePaciente();
          limpiarRedux();
          history.push('/pacientes');
        } else if (result.code === 305) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: `${t(result.msg)}: '${objEnviar.titulo}'`,
            }),
          );
        }
        if (result.code === 400) {
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: result.msg,
            }),
          );
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-get-error')} ${err.toString()}`,
          }),
        );
      });
  };
  /** handler del evento submit */
  const handleSubmitForm = () => {
    switch (seleccionaFormato) {
      case '1':
        if (!constructor.constructor.respuestaBreve?.unidad.label) {
          setErrores((preE) => ({ ...preE, errorAutocomplete: true }));
          setDisableContinuar(true);
          return;
        }
        if (
          constructor.constructor.respuestaBreve.unidad.label === 'OTRA'
          && !constructor.constructor.respuestaBreve.otraUnidad
        ) {
          setErrores((preE) => ({ ...preE, errorEspecifica: true }));
          setDisableContinuar(true);
          return;
        }
        break;
      case '2':
        break;
      case '3':
        if (paso === 0) {
          let correcto = true;
          setConstructor((preCons) => {
            const cleanData = preCons.constructor.casillasOpciones!.arrOpciones.filter(
              (op) => op.nombreOpcion.length > 0,
            );
            let arrOpClean = uniqueObjArray(cleanData, 'nombreOpcion');
            const lenArr = arrOpClean.length;
            if (lenArr < 2) {
              correcto = false;
            }
            if (lenArr === 0) {
              arrOpClean = [
                { nombreOpcion: '', checked: false, arraySubOpciones: [] },
                { nombreOpcion: '', checked: false, arraySubOpciones: [] },
              ];
            }
            return {
              ...preCons,
              constructor: {
                ...preCons.constructor,
                casillasOpciones: {
                  arrOpciones: arrOpClean,
                },
              },
            };
          });
          if (correcto) {
            setPaso((preP) => preP + 1);
          } else {
            setErrores((preE) => ({ ...preE, errorAlMenosDosOpciones: true }));
            setDisableContinuar(true);
          }
        }
        if (paso === 1) {
          setConstructor((preCons) => {
            const cleanData = preCons.constructor.casillasOpciones!.arrOpciones.filter(
              (op) => op.nombreOpcion !== '',
            );
            const arrOpClean = cleanData.map((sAO) => {
              const arrSoClean = uniqueObjArray(
                sAO.arraySubOpciones.filter((op) => op.nombreOpcion.length > 0),
                'nombreOpcion',
              );
              return { ...sAO, arraySubOpciones: arrSoClean };
            });
            return {
              ...preCons,
              constructor: {
                ...preCons.constructor,
                casillasOpciones: {
                  ...preCons.constructor.casillasOpciones,
                  arrOpciones: arrOpClean,
                },
              },
            };
          });
          setPaso((preP) => preP + 1);
        }
        if (paso >= 2) {
          enviarInformacion();
        }
        return;
      case '4':
        if (paso === 0) {
          let correcto = true;
          setConstructor((preCons) => {
            const cleanData = preCons.constructor.casillasOpciones!.arrOpciones.filter(
              (op) => op.nombreOpcion.length > 0,
            );
            let arrOpClean = uniqueObjArray(cleanData, 'nombreOpcion');
            const lenArr = arrOpClean.length;
            if (lenArr < 2) {
              correcto = false;
            }
            if (lenArr === 0) {
              arrOpClean = [
                { nombreOpcion: '', checked: false, arraySubOpciones: [] },
                { nombreOpcion: '', checked: false, arraySubOpciones: [] },
              ];
            }
            return {
              ...preCons,
              constructor: {
                ...preCons.constructor,
                casillasOpciones: {
                  arrOpciones: arrOpClean,
                },
              },
            };
          });
          if (correcto) {
            setPaso((preP) => preP + 1);
          } else {
            setErrores((preE) => ({ ...preE, errorAlMenosDosOpciones: true }));
            setDisableContinuar(true);
          }
        }
        if (paso === 1) {
          setConstructor((preCons) => {
            const cleanData = preCons.constructor.casillasOpciones!.arrOpciones.filter(
              (op) => op.nombreOpcion !== '',
            );
            const arrOpClean = cleanData.map((sAO) => {
              const arrSoClean = uniqueObjArray(
                sAO.arraySubOpciones.filter((op) => op.nombreOpcion.length > 0),
                'nombreOpcion',
              );
              return { ...sAO, arraySubOpciones: arrSoClean };
            });
            return {
              ...preCons,
              constructor: {
                ...preCons.constructor,
                casillasOpciones: {
                  ...preCons.constructor.casillasOpciones,
                  arrOpciones: arrOpClean,
                },
              },
            };
          });
          setPaso((preP) => preP + 1);
        }
        if (paso >= 2) {
          enviarInformacion();
        }
        return;
      case '5':
        if (paso === 0) {
          let correcto = true;
          setConstructor((preCons) => {
            const cleanData = preCons.constructor.listaDesplegable!.arrValores.filter(
              (op) => op.length > 0,
            );
            let arrValClean = uniqueStringArray(cleanData);
            const lenArr = arrValClean.length;
            if (lenArr < 2) {
              correcto = false;
            }
            if (lenArr === 0) {
              arrValClean = ['', ''];
            }
            return {
              ...preCons,
              constructor: {
                ...preCons.constructor,
                listaDesplegable: {
                  ...preCons.constructor.listaDesplegable!,
                  arrValores: arrValClean,
                },
              },
            };
          });
          if (correcto) {
            setPaso((preP) => preP + 1);
          } else {
            setErrores((preE) => ({ ...preE, errorAlMenosDosOpciones: true }));
            setDisableContinuar(true);
          }
          return;
        }
        if (paso === 1) {
          setPaso((preP) => preP + 1);
          return;
        }
        break;
      case '6':
        if (paso < 2) {
          setPaso((preP) => preP + 1);
          return;
        }
        break;
      case '7':
        if (paso < 2) {
          setPaso((preP) => preP + 1);
          return;
        }
        break;
      case '8':
        break;
      default:
        break;
    }
    enviarInformacion();
  };

  const activeRegresar = () => {
    let valRet = true;
    if (
      (seleccionaFormato === '7'
        || seleccionaFormato === '6'
        || seleccionaFormato === '5'
        || seleccionaFormato === '4'
        || seleccionaFormato === '3')
      && paso !== 0
    ) {
      valRet = false;
    }
    return valRet;
  };

  const numeroDePasos = () => {
    switch (seleccionaFormato) {
      case '1':
        return 1;
      case '2':
        return 1;
      case '3':
        return 3;
      case '4':
        return 3;
      case '5':
        return 3;
      case '6':
        return 3;
      case '7':
        return 3;
      case '8':
        return 1;
      case '9':
        return 1;
      default:
        break;
    }
    return 1;
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id="perfil-paciente"
      aria-labelledby="perfil-paciente"
    >
      {value === index && (
        <Paper square className="p-4">
          <div className="mb-0.5 relative border-solid border-0 border-b border-gray-600 pb-6">
            <h1 className="text-center text-blue-800 mt-0 font-medium">{t('agregar_variable')}</h1>
            <div className="absolute top-0">
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  regresar(false);
                }}
              >
                {t('regresar_a_mc')}
              </Button>
            </div>
            <div className="px-4 py-3 rounded bg-blue-200 inline-block mb-4">
              <span>
                {t('motivo_consulta')}
                :&nbsp;
              </span>
              <span className="text-blue-500 font-bold">{nombreDelMC}</span>
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-4 items-end">
              <h3 className="mt-4 mb-2 text-gray-600 font-medium">
                {t('escribe_el_nombre_variable')}
              </h3>
              {refSeleccionaFormato && (
                <h3 className="mt-4 mb-2 text-gray-600 font-medium">
                  {t('selecciona_un_formato')}
                </h3>
              )}
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
              <TextField
                id="text-field-motivo"
                variant="outlined"
                value={constructor.datosComponente.titulo}
                onChange={handleChangeNombreVariable}
                inputProps={{ maxLength: 75, autoComplete: 'off', 'aria-autocomplete': 'none' }}
                fullWidth
                multiline
                rows={1}
              />
              {refSeleccionaFormato && (
                <FormControl variant="outlined" fullWidth>
                  <Select
                    id="select-selecciona-formato"
                    onChange={handleChangeSeleccionaFormato}
                    value={seleccionaFormato}
                    /**
                     * Un select no renderea por defecto un componente select
                     * (eso lo hace el native select) al contrario renderea un input
                     * (en realidad es un input de material no un input nativo)
                     * para modificar el comportamiento de este, se tiene que cambiar sus
                     * estilos por defecto por lo cual le mandamos props al input
                     * y esta son classes (para modificar su diseño por default)
                     * al ser dificil mandarlas por un framework externo como tailwindcss
                     * se tiene que usar usestylesde material ui, y alli
                     * se le mandan los estilos para que sobreescriban los que estan por defecto
                     */
                    inputProps={{ classes: { root: classes.root } }}
                  >
                    <MenuItem value="1">
                      <ListItemIcon>
                        <ShortTextIcon />
                      </ListItemIcon>
                      <ListItemText>{t('respuesta_breve')}</ListItemText>
                    </MenuItem>
                    <MenuItem value="2">
                      <ListItemIcon>
                        <SubjectIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('parrafo')} />
                    </MenuItem>
                    <MenuItem value="3">
                      <ListItemIcon>
                        <RadioButtonCheckedIcon />
                      </ListItemIcon>
                      <ListItemText>{t('opcion_multiple')}</ListItemText>
                    </MenuItem>
                    <MenuItem value="4">
                      <ListItemIcon>
                        <CheckBoxIcon />
                      </ListItemIcon>
                      <ListItemText>{t('casillas_verificacion')}</ListItemText>
                    </MenuItem>
                    <MenuItem value="5">
                      <ListItemIcon>
                        <ArrowDropDownCircleIcon />
                      </ListItemIcon>
                      <ListItemText>{t('lista_desplegable')}</ListItemText>
                    </MenuItem>
                    <MenuItem value="6">
                      <ListItemIcon>
                        <LinearScaleIcon />
                      </ListItemIcon>
                      <ListItemText>{t('escala_lineal')}</ListItemText>
                    </MenuItem>
                    <MenuItem value="7">
                      <ListItemIcon>
                        <AppsIcon />
                      </ListItemIcon>
                      <ListItemText>{t('tabla')}</ListItemText>
                    </MenuItem>
                    <MenuItem value="8">
                      <ListItemIcon>
                        <TodayIcon />
                      </ListItemIcon>
                      <ListItemText>{t('fecha')}</ListItemText>
                    </MenuItem>
                    <MenuItem value="9">
                      <ListItemIcon>
                        <QueryBuilderIcon />
                      </ListItemIcon>
                      <ListItemText>{t('hora')}</ListItemText>
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
          <Paper square elevation={0} className="pt-4">
            {seleccionaFormato === '9' && (
              <BuilderHora variable={constructor} setVariable={setConstructor} />
            )}
            {seleccionaFormato === '8' && (
              <BuilderFecha variable={constructor} setVariable={setConstructor} />
            )}
            {seleccionaFormato === '6' && (
              <BuilderEscala variable={constructor} setVariable={setConstructor} paso={paso} />
            )}
            {seleccionaFormato === '5' && (
              <BuilderListaDesplegable
                variable={constructor}
                setVariable={setConstructor}
                errores={errores}
                setErrores={setErrores}
                setDisableContinuar={setDisableContinuar}
                paso={paso}
              />
            )}
            {seleccionaFormato === '4' && (
              <BuilderCasillasVerificacion
                variable={constructor}
                setVariable={setConstructor}
                errores={errores}
                setErrores={setErrores}
                setDisableContinuar={setDisableContinuar}
                paso={paso}
              />
            )}
            {seleccionaFormato === '3' && (
              <BuilderOpcionMultiple
                variable={constructor}
                setVariable={setConstructor}
                errores={errores}
                setErrores={setErrores}
                setDisableContinuar={setDisableContinuar}
                paso={paso}
              />
            )}
            {seleccionaFormato === '2' && (
              <BuilderParrafo variable={constructor} setVariable={setConstructor} />
            )}
            {seleccionaFormato === '1' && (
              <BuilderRespuestaBreve
                variable={constructor}
                setVariable={setConstructor}
                errores={errores}
                setErrores={setErrores}
                setDisableContinuar={setDisableContinuar}
              />
            )}
            {seleccionaFormato === '7' && (
              <BuilderTabla
                variable={constructor}
                setVariable={setConstructor}
                errores={errores}
                setErrores={setErrores}
                setDisableContinuar={setDisableContinuar}
                paso={paso}
              />
            )}
            {seleccionaFormato && (
              <div className="flex flex-wrap justify-end">
                <h3 className="w-full text-right m-0 mb-2 text-blue-800 font-normal">
                  {`Paso ${paso + 1} de ${numeroDePasos()}`}
                </h3>
                {paso > 0 && (
                  <div className="px-10">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      onClick={() => setPaso((prePaso) => prePaso - 1)}
                      startIcon={<ArrowBackIcon />}
                      disabled={activeRegresar()}
                    >
                      {t('regresar')}
                    </Button>
                  </div>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  onClick={handleSubmitForm}
                  startIcon={numeroDePasos() === paso + 1 && <SaveIcon />}
                  endIcon={numeroDePasos() !== paso + 1 && <ArrowForwardIcon />}
                  disabled={disableContinuar}
                >
                  {numeroDePasos() === paso + 1 ? t('guardar') : t('continuar')}
                </Button>
              </div>
            )}
          </Paper>
        </Paper>
      )}
    </div>
  );
}

export default Builder;
