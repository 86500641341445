import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table';
import {
  Dialog,
  DialogContent,
  Slide,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { IModalUltimosCambiosProps } from './types';
import useStyles from './styles';

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);

const TableDraw = (val: any) => {
  // Use the state and functions returned from useTable to build your UI
  const { columns, data } = val;
  const classes = useStyles();
  const {
    getTableProps, headerGroups, rows, prepareRow,
  } = useTable({
    columns,
    data,
  });
  // Render the UI for your table
  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps()} className={classes.header}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const rowIndex = parseInt(cell.row.id, 10) || 0;
                return (
                  <TableCell
                    {...cell.getCellProps()}
                    className={rowIndex % 2 === 0 ? `${classes.row}` : 'inherit'}
                  >
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

function ModalUltimosCambios(props: IModalUltimosCambiosProps) {
  const { t } = useTranslation();
  const columnas = [
    {
      Header: t('administrador_equipo_tabla_administrador'),
      accessor: 'nombre',
    },
    {
      Header: t('administrador_equipo_tabla_fecha'),
      accessor: 'fecha_movimiento',
    },
    {
      Header: t('administrador_equipo_tabla_bajas'),
      accessor: 'bajas',
    },
    {
      Header: t('administrador_equipo_tabla_altas'),
      accessor: 'altas',
    },
  ];
  const { open, callBackClose, lastChanges } = props;
  const handleClose = (e: any) => {
    callBackClose(e);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="delete-dialog-titulo"
      aria-describedby="delete-dialog-descripcion"
      fullWidth
      maxWidth="lg"
    >
      <div className="flex justify-between items-center pl-4 bg-blue-800 text-white">
        <h2 className="font-normal">{t('administrador_equipo_tabla_ultimos_cambios')}</h2>
        <IconButton aria-label="cerrar" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className="text-center">
        <TableDraw columns={columnas} data={lastChanges} />
      </DialogContent>
    </Dialog>
  );
}

export default ModalUltimosCambios;
